import type { I18nLocale } from "@/modules/i18n/types"
import type { FunctionComponent } from "react"

import { useLocale } from "@/modules/locales/useLocale"
import facebook from "@/modules/socials/assets/facebook-white.svg"
import instagram from "@/modules/socials/assets/instagram-white.svg"
import linkedin from "@/modules/socials/assets/linkedin-white.svg"
import tiktok from "@/modules/socials/assets/tiktok-white.svg"
import youtube from "@/modules/socials/assets/youtube-white.svg"
import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"

import styles from "./Socials.module.css"

type SocialNetworkName = "facebook" | "instagram" | "linkedin" | "tiktok" | "youtube"

const SOCIAL_NETWORKS: SocialNetworkName[] = ["youtube", "instagram", "tiktok", "facebook", "linkedin"]
const SOCIAL_NETWORK_URL_PER_LOCALE: Record<SocialNetworkName, Partial<Record<I18nLocale | "default", string>>> = {
  facebook: {
    de: "https://www.facebook.com/jobteaserde",
    en: "https://www.facebook.com/profile.php?id=100066643249092",
    es: "https://www.facebook.com/jobteaser.es",
    fr: "https://www.facebook.com/jobteaser",
  },
  instagram: {
    de: "https://www.instagram.com/jobteaser_de/",
    es: "https://www.instagram.com/jobteaser_es/",
    fr: "https://www.instagram.com/jobteaser_fr/",
  },
  linkedin: {
    da: "https://www.linkedin.com/company/graduateland/",
    default: "https://www.linkedin.com/company/jobteaser/",
    fi: "https://www.linkedin.com/company/graduateland/",
    no: "https://www.linkedin.com/company/graduateland/",
    sv: "https://www.linkedin.com/company/graduateland/",
  },
  tiktok: {
    de: "https://www.tiktok.com/@jobteaser_de",
    fr: "https://www.tiktok.com/@jobteaser_fr",
  },
  youtube: {
    de: "https://www.youtube.com/@jobteaserdach2094",
    default: "https://www.youtube.com/c/JobTeaserEMEA",
    fr: "https://www.youtube.com/@JobTeaserFrance",
  },
}

export const SocialsList: FunctionComponent = () => {
  const locale = useLocale()
  const srcList: Record<SocialNetworkName, string> = {
    facebook: getAssetImportUrl(facebook),
    instagram: getAssetImportUrl(instagram),
    linkedin: getAssetImportUrl(linkedin),
    tiktok: getAssetImportUrl(tiktok),
    youtube: getAssetImportUrl(youtube),
  }

  return (
    <ul className={styles.main}>
      {SOCIAL_NETWORKS.map(socialNetworkName => {
        const url =
          SOCIAL_NETWORK_URL_PER_LOCALE[socialNetworkName][locale] ||
          SOCIAL_NETWORK_URL_PER_LOCALE[socialNetworkName].default

        if (!url) {
          return null
        }

        return (
          <li key={socialNetworkName} className={styles.item}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img src={srcList[socialNetworkName]} alt={`Logo ${socialNetworkName}`} className={styles.icon} />
            </a>
          </li>
        )
      }).filter(Boolean)}
    </ul>
  )
}
